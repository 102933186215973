import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import NewsSection from "components/NewsSection"
import AboutContent from "../page_components/about/AboutContent"

const About = ({ data }) => {
  const breadcrumbs_data = [
    {
      name: "Poznaj nas",
      href: "/poznaj-nas",
    },
  ]

  return (
    <Layout
      seo={{
        title: "Poznaj nas",
        description:
          "Z podologią związana jestem od 2008 roku. Wiedzę i doświadczenie w zawodzie zdobywałam na licznych kursach i szkoleniach w Polsce oraz za granicą.",
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header="Poznaj nas" />
      <AboutContent data={data?.allWpPage?.nodes[0]?.acfAbout} />
      <NewsSection
        title="SPRAWDŹ CO U NAS SŁYCHAĆ"
        heading="Aktualności"
        posts={data?.companyNews?.nodes}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDo5NzE=" } }) {
      nodes {
        acfAbout {
          text
          text2
          image {
            altText
            sourceUrl
          }
          imageBanner {
            altText
            sourceUrl
          }
        }
      }
    }
    companyNews: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { name: { eq: "Z życia kliniki" } } }
        }
      }
      limit: 3
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        slug
        acfPosts {
          image {
            altText
            sourceUrl
          }
        }
        excerpt
        title
      }
    }
  }
`

export default About
