import "./styles.scss"

import React from "react"

const AboutContent = ({ data }) => {
  const { image, imageBanner, text, text2 } = data
  return (
    <section className="about-content">
      <div className="container">
        <div className="about-content__wrapper">
          <div className="flex-column-reverse flex-lg-row about-content__row1">
            <div className="col-lg-6 col-12">
              <div
                className="about-content__row1-text"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
            <div className="col-lg-6 col-12">
              <img src={image?.sourceUrl} alt={image?.altText} />
            </div>
          </div>
          <div className="row about-content__row2">
            <div className="col-12">
              <div className="about-content__row2-img">
                <img src={imageBanner?.sourceUrl} alt={imageBanner?.altText} />
              </div>
            </div>
          </div>
          <div className="row about-content__row3">
            <div
              className="about-content__row3-text"
              dangerouslySetInnerHTML={{ __html: text2 }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutContent
